//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiMenu, mdiClose, mdiBell, mdiChevronRight } from '@mdi/js'
import { scoopChevronDown } from '@/src/custom-icons'
import dashboardMenuMixin from '@/mixins/dashboardMenu'
import specialOffersMixin from '@/mixins/specialOffers'

export default {
  mixins: [dashboardMenuMixin, specialOffersMixin],
  data () {
    return {
      icons: {
        scoopChevronDown
      },
      menuIcon: mdiMenu,
      bellIcon: mdiBell,
      rightIcon: mdiChevronRight,
      showNavDrawer: false,
      loading: false,
      renderComponent: true
    }
  },
  async fetch () {
    if (this.allowRatesbotOffers) {
      this.loading = true
      await Promise.all([this.getRatesbotOffers()])
      this.loading = false
    }
  },
  computed: {
    eligibleSpecialOffers () {
      const specialOffers = { ...this.$store.state.policies.specialOffers }
      return Object.values(specialOffers).filter(e => ['1', '2', '3', '5'].includes(e.scenario))
    },
    currentLinkIndex () {
      const tabs = this.availableLinks.reduce((acc, cur) => {
        const key = cur.dropdownGroup ? cur.dropdownGroup : cur.scope
        if (Array.isArray(acc[key])) {
          acc[key].push(cur)
        } else {
          acc[key] = [cur]
        }
        return acc
      }, {})
      return Object.keys(tabs).findIndex((scope) => {
        return tabs[scope].some(link => !link.isMobileOnly && this.$route.name.includes(link.scope))
      })
    },
    hideSlider () {
      return this.currentLinkIndex < 0 || (this.$route.name.includes('offer-offer-num') && !this.getEligibleSpecialOffers().length)
    },
    lookupCodes () {
      return this.$store.state.auth.user && this.$store.state.auth.user.attributes['custom:lookup_codes'] ? this.$store.state.auth.user.attributes['custom:lookup_codes'].split(/\s/) : []
    }
  },
  watch: {
    'eligibleSpecialOffers.length': {
      handler (val) {
        this.forceRerender()
      }
    }
  },
  mounted () {
    this.$root.$on('nav-drawer-value-change', (value) => {
      this.menuIcon = value ? mdiClose : mdiMenu
    })
    this.$root.$on('nav-drawer-force-rerender', this.forceRerender)
  },
  methods: {
    getEligibleSpecialOffers () {
      const specialOffers = { ...this.$store.state.policies.specialOffers }
      return Object.values(specialOffers).filter(e => ['1', '2', '3', '5'].includes(e.scenario))
    },
    openNavDrawer () {
      this.$root.$emit('open-nav-drawer')
    },
    // Hack to center menu slider after offers are loaded
    async forceRerender () {
      this.renderComponent = false
      await this.$nextTick()
      this.renderComponent = true
    }
  }
}
