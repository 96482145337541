//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DebugDetailPanel',
  props: {
    color: {
      type: String,
      required: true
    },
    storeType: {
      type: String,
      required: true
    },
    store: {
      type: [Array, Object],
      default: undefined
    }
  },
  data () {
    return {
      sections: [
        { heading: 'Store', contents: ['store'] }
      ],
      id: `debug-panel-${this.storeType}`
    }
  },
  methods: {
    isEmpty (object) {
      if (!object) {
        return true
      }
      if (object instanceof Array || object instanceof String) {
        return object.length === 0
      } else if (object instanceof Object) {
        return Object.keys(object).length === 0
      }
    }
  }
}
