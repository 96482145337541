'use strict'

import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  new VuexPersistence({
    /* your options */
    modules: ['settings'],
    storage: window.localStorage
    // https://www.npmjs.com/package/vuex-persist#usage
  }).plugin(store)
}
