export default {
  data () {
    return {
      specialOffers: {}
    }
  },
  computed: {
    allowRatesbotOffers () {
      return this.$auth.isCustomer ? this.$config.APP_CONFIG.allowRatesbotOffers.user : this.$config.APP_CONFIG.allowRatesbotOffers.broker
    }
  },
  methods: {
    async getRatesbotOffers () {
      if (this.allowRatesbotOffers) {
        const lookupCodes = this.$store.state.auth.user && this.$store.state.auth.user.attributes['custom:lookup_codes'] ? this.$store.state.auth.user.attributes['custom:lookup_codes'].split(/\s/) : []
        await lookupCodes.map(async (lookupCode) => {
          const res = await this.$api.getRatesbotOffers(lookupCode)
          return res.map((specialOffer) => {
            this.$set(this.specialOffers, specialOffer.uniqPolicy, {
              ...specialOffer,
              scenario: specialOffer.note.replace('scenario:', '').replace('{', '').replace('}', '')
            })
            return this.$store.dispatch('policies/setSpecialOffer', {
              ...this.specialOffers[specialOffer.uniqPolicy]
            })
          })
        })
      }
    }
  }
}
