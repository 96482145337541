import brokerPortalDomains from '@/src/broker-portal-domains'

export default ({ $config: { CHAT } }) => {
  if (brokerPortalDomains.includes(window.location.host)) {
    return
  }
  // Add script tag to head
  const script = document.createElement('script')
  script.async = true
  script.id = 'chat'
  script.src = `${CHAT.RC_URL}/inContact/ChatClient/js/embed.min.js`
  document.head.appendChild(script)
}
