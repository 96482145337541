const defaults = {
  timeout: 2500
}

export const state = () => ({
  show: false,
  message: null,
  timeout: defaults.timeout
})

export const mutations = {
  SET (s, notificaiton) {
    s.message = notificaiton.message
    s.timeout = notificaiton.timeout || defaults.timeout
    s.show = !!s.message
  }
}

export const actions = {
  showMessage ({ commit }, { message, timeout, show }) {
    commit('SET', { message, timeout, show })
  },
  hideMessage ({ commit }) {
    commit('SET', {})
  }
}
