import Vue from 'vue'

export const state = () => ({
  loaded: false,
  firstName: null,
  lastName: null,
  fullName: null,
  birthday: null,
  address: null,
  city: null,
  province: null,
  postalCode: null
})

export const mutations = {
  SET_PROFILE (s, profile) {
    Object.assign(s, profile)
    s.loaded = true
  },
  CLEAR_PROFILE (s) {
    Object.keys(s).forEach((key) => {
      delete s[key]
    })
    s.loaded = false
  }
}

export const actions = {
  async setProfile ({ commit, state: currentState }, forceRequest = false) {
    if (!currentState.loaded || forceRequest) {
      const res = await Vue.prototype.$api.getProfile()
      const profile = {
        firstName: res.first_name,
        lastName: res.last_name,
        fullName: res.full_name,
        birthday: res.birth_date,
        address: res.address_1,
        city: res.city,
        province: res.cd_state_code,
        postalCode: res.postal_code
      }
      commit('SET_PROFILE', profile)
    }
  },
  clearProfile ({ commit }) {
    commit('CLEAR_PROFILE')
  }
}
