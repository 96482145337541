//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head () {
    return {
      title: this.title
    }
  },
  computed: {
    statusCode () {
      return this.error.statusCode.toString().match(/^(503)$/) ? this.error.statusCode : 'other'
    },
    title () {
      return this.getContent('title')
    },
    subtitles () {
      return this.getContent('subtitles')
    },
    img () {
      if (this.error.statusCode.toString().match(/^(503)$/)) {
        return {
          src: '/maintenance-icon.svg',
          alt: 'maintenance icon'
        }
      }
      return null
    }
  },
  methods: {
    getContent (type) {
      let key = `errorPage.${(this.$auth.isAuthenticated) ? 'auth' : 'unauth'}.${this.statusCode}.${type}`
      if (!this.$i18n.te(key)) {
        key = `errorPage.${(this.$auth.isAuthenticated) ? 'auth' : 'unauth'}.other.${type}`
      }
      const st = this.$i18n.t(key, { ...this.error.maintenance })
      return st
    }
  }
}
