const defaults = {
  myProofOfInsuranceStatus: false
}

export const state = () => ({
  myProofOfInsuranceStatus: defaults.myProofOfInsuranceStatus
})

export const mutations = {
  SET_MY_PROOF_OF_INSURANCE_STATUS (s, status) {
    s.myProofOfInsuranceStatus = status
  }
}

export const actions = {
  async setMyProofOfInsuranceStatus ({ commit, state: currentState }) {
    const myProofOfInsuranceStatus = await this.$api.getMyProofOfInsuranceStatus()
    commit('SET_MY_PROOF_OF_INSURANCE_STATUS', myProofOfInsuranceStatus)
  }
}
