/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:32a177f8-a0e2-4112-954f-46fd325e97ab",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_804Kcbc7u",
    "aws_user_pools_web_client_id": "4i0d227l6v24td2ouqfl3hj6us",
    "oauth": {
        "domain": "clientportal-prod.auth.ca-central-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://broker.scoopinsurance.ca/",
        "redirectSignOut": "https://broker.scoopinsurance.ca/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 10,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://wwjprl7nk5dldacooncozs6wa4.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "clientportalApiGw-prod",
            "endpoint": "https://l6saeap4r2.execute-api.ca-central-1.amazonaws.com/prod",
            "region": "ca-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "clientportal-storage112844-prod",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
