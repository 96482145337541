import Vue from 'vue'
import { Amplify, Hub } from 'aws-amplify'
import awsExports from '@/src/aws-exports'

import brokerPortalDomains from '@/src/broker-portal-domains'
import customerPortalDomains from '@/src/customer-portal-domains'

const origin = process.env.ENV === 'local' || brokerPortalDomains.some(x => location.origin === `https://${x}`) || customerPortalDomains.some(x => location.origin === `https://${x}`) ? location.origin : ''
awsExports.oauth.redirectSignIn = awsExports.oauth.redirectSignOut = `${origin}/`

const awsConfig = { ...awsExports, ssr: process.env.APP_CONFIG.ssr }
Amplify.configure(awsConfig)

export default (context, inject) => {
  Hub.listen('auth', (data) => {
    if (context.$api && data.payload.event === 'signIn') {
      context.$api.registerClientToAgency('registered')
    }
    if (data.payload.event.match(/(signOut|oAuthSignOut)/)) {
      context.store.dispatch('policies/clearPolicies')
      context.store.dispatch('profile/clearProfile')
      context.store.dispatch('announcements/clearAnnouncements')
    }
  })

  context.$awsConfig = Vue.prototype.$awsConfig = Vue.$awsConfig = awsConfig
  inject('awsConfig', awsConfig)
}
