import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

export default (context) => {
  Vue.use(VueReCaptcha, {
    siteKey: process.env.RECAPTCHA_SITE_KEY,
    loaderOptions: {
      // https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
      autoHideBadge: true
    }
  })
}
