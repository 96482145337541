var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:{ authenticated: _vm.$auth.isAuthenticated }},[(_vm.$auth.isAuthenticated)?_c('v-app-bar',{attrs:{"color":"primary","height":"auto","fixed":"","app":""}},[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('announcements-bar',{ref:"announcements-bar",attrs:{"announcements":_vm.announcements}}),_vm._v(" "),_c('div',{staticClass:"nav-bar d-flex justify-space-between align-center mx-auto"},[_c('div',{staticClass:"order-2 order-md-1"},[(_vm.$vuetify.breakpoint.smAndDown)?_c('img',{staticClass:"justify-center",attrs:{"alt":_vm.$i18n.t('scoop'),"height":"32","width":"52","src":"/scoop-eyes-white.svg"}}):_c('img',{attrs:{"alt":_vm.$i18n.t('scoop'),"height":"44","width":"111","src":"/scoop-white.svg"}})]),_vm._v(" "),_c('div',{staticClass:"dashboard-menu-container order-1 order-md-2"},[_c('dashboard-menu')],1),_vm._v(" "),_c('div',{staticClass:"user-menu-container ml-md-auto order-3"},[_c('user-menu')],1)])],1)]):_vm._e(),_vm._v(" "),(_vm.$auth.isAuthenticated)?_c('dashboard-menu-drawer',{style:(_vm.menuDrawerStyle)}):_vm._e(),_vm._v(" "),_c('v-main',{staticClass:"main",style:(_vm.mainStyle)},[_c('nuxt')],1),_vm._v(" "),_c('client-only',[_c('scoop-footer',{attrs:{"aria-label":"Footer"}})],1),_vm._v(" "),(false)?_c('v-snackbar',{attrs:{"value":_vm.$store.state.workbox.isUpdate,"timeout":-1},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"aria-label":"Refresh page","color":"blue","text":""},on:{"click":function($event){return _vm.reloadPage()}}},'v-btn',attrs,false),[_vm._v("\n        Refresh\n      ")])]}}],null,false,3457630403)},[_vm._v("\n    A new version is available. Refresh to get the latest version.\n    ")]):_vm._e(),_vm._v(" "),_c('notification-snackbar'),_vm._v(" "),_c('base-dialog',{attrs:{"vuetify-props":{
      value: _vm.timeoutWarning,
      width: 436
    }},on:{"close":function($event){return _vm.closeTimeout()}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('label',{attrs:{"id":"timeout_dialog_header","tabindex":"0","role":"alert"}},[_vm._v("\n        "+_vm._s(_vm.$i18n.t('timeoutDialog.header'))+"\n      ")])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"text-h4"},[_vm._v("\n          "+_vm._s(_vm.timeoutMinutes + ':' + _vm.timeoutSeconds)+"\n        ")]),_vm._v(" "),_c('v-btn',{staticClass:"mt-6 text-capitalize",attrs:{"color":"primary","elevation":"0","aria-label":[
            _vm.$i18n.t('timeoutDialog.button_alt1'),
            _vm.timeoutMinutes + ' ',
            _vm.$i18n.t('timeoutDialog.button_alt_minutes'),
            ' ' + _vm.timeoutSeconds + ' ',
            _vm.$i18n.t('timeoutDialog.button_alt_seconds'),
            _vm.$i18n.t('timeoutDialog.button_alt2')
          ].join('')},on:{"click":function($event){return _vm.closeTimeout()}}},[_vm._v("\n          "+_vm._s(_vm.$i18n.t('timeoutDialog.button'))+"\n        ")])],1)]},proxy:true}])}),_vm._v(" "),(!_vm.$config.ENV.match(/(uat|uatb|prod)$/))?_c('store-debug',{staticStyle:{"position":"fixed","bottom":"0","right":"0","z-index":"999"}}):_vm._e(),_vm._v(" "),(_vm.$auth.isAuthenticated && _vm.$route.name && _vm.$auth.isCustomer)?_c('report-form-2'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }