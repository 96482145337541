import { render, staticRenderFns } from "./ErrorCard.vue?vue&type=template&id=61f6083e&scoped=true&"
import script from "./ErrorCard.vue?vue&type=script&lang=js&"
export * from "./ErrorCard.vue?vue&type=script&lang=js&"
import style0 from "./ErrorCard.vue?vue&type=style&index=0&id=61f6083e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f6083e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardTitle: require('/builds/ratesdotca/scoop/clientportal/components/CardTitle.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VCol,VContainer,VRow})
