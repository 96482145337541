//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiClose } from '@mdi/js'

export default {
  props: {
    announcements: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return { mdiClose }
  },
  methods: {
    closeAnnouncement (announcementId) {
      this.$store.dispatch('announcements/closeAnnouncement', announcementId)
    }
  }
}
