//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subtitles: {
      type: String,
      required: true
    },
    login: {
      type: Boolean,
      default: true
    },
    img: {
      type: Object,
      default: null
    }
  },
  computed: {
    subtitlesFormatted () {
      if (Array.isArray(this.subtitles)) {
        return this.subtitles.join('<br>')
      }
      return this.subtitles
    }
  }
}
