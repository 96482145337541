//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { scoopUser, scoopChevronDown } from '@/src/custom-icons'

export default {
  data () {
    return {
      icons: {
        scoopChevronDown,
        scoopUser
      },
      currentPath: '/profile'
    }
  },
  computed: {
    currentLinkIndex () {
      return this.$route.path === this.currentPath ? 0 : -1
    },
    hideSlider () {
      return this.currentLinkIndex < 0
    }
  },
  methods: {
    async logout () {
      try {
        await this.$store.dispatch('auth/logout')
        this.$router.push(this.localePath('/login'))
        this.$dd.removeUser()
      } catch (err) {
        console.warn(err)
      }
    }
  }
}
