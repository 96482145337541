/* global Request, fetch */
const isMaintenance = async () => {
  try {
    const req = new Request('/maintenance.json', { method: 'GET', headers: { Accept: 'application/json, text/json' } })
    const res = await fetch(req)
    if (res.ok) {
      return await res.json()
    }
  } catch (err) {
    // console.log(err)
  }
  return null
}

export default async ({ store, query, error }) => {
  const maintenance = await isMaintenance()
  if (maintenance) {
    if (maintenance.logout && store.state.auth.isAuthenticated) {
      await store.dispatch('auth/logout')
    }
    error({ statusCode: 503, message: 'Maintenance', maintenance })
  }
}
