//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      show: false
    }
  },
  watch: {
    show: {
      handler (newValue) {
        if (!newValue) {
          this.$store.dispatch('notification/hideMessage')
        }
      }
    },
    '$store.state.notification.show': {
      handler (newValue) {
        if (newValue) {
          this.show = newValue
        }
      }
    }
  }
}
