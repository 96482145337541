//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiOpenInNew, mdiBell, mdiChevronRight } from '@mdi/js'
import dashboardMenuMixin from '@/mixins/dashboardMenu'
import specialOffersMixin from '@/mixins/specialOffers'

export default {
  mixins: [dashboardMenuMixin, specialOffersMixin],
  data () {
    return {
      mdiOpenInNew,
      bellIcon: mdiBell,
      rightIcon: mdiChevronRight,
      showNavDrawer: false,
      loading: false,
      accordionSpecs: {
        attrs: {
          classes: {
            itemExpandedActive: 'item-expanded',
            panelHeader: 'px-0'
          },
          heading: {
            tag: 'div',
            classes: {}
          },
          subHeading: {
            tag: 'h3',
            classes: {}
          }
        }
      }
    }
  },
  async fetch () {
    if (this.allowRatesbotOffers) {
      this.loading = true
      await Promise.all([this.getRatesbotOffers()])
      this.loading = false
    }
  },
  computed: {
    lookupCodes () {
      return this.$store.state.auth.user && this.$store.state.auth.user.attributes['custom:lookup_codes'] ? this.$store.state.auth.user.attributes['custom:lookup_codes'].split(/\s/) : []
    }
  },
  watch: {
    showNavDrawer: {
      handler (value) {
        this.$root.$emit('nav-drawer-value-change', value)
      }
    }
  },
  mounted () {
    this.$root.$on('open-nav-drawer', () => {
      this.showNavDrawer = true
    })
  }
}
