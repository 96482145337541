import specialOffersMixin from '@/mixins/specialOffers'

export default {
  mixins: [specialOffersMixin],
  computed: {
    links () {
      const data = [
        {
          name: this.$i18n.t('navigation.overview'),
          path: '/',
          scope: 'index'
        },
        {
          name: this.$i18n.t('navigation.icp'),
          path: '/admin/icp',
          scope: 'icp',
          dropdownGroup: 'icp',
          isBrokerPortal: true
        },
        {
          name: this.$i18n.t('navigation.icpAdjustments'),
          path: '/admin/icp/adjustments',
          scope: 'icp-adjustments',
          dropdownGroup: 'icp',
          isBrokerPortal: true
        },
        {
          name: this.$i18n.t('navigation.icpUploadDownload'),
          path: '/admin/icp/upload-download',
          scope: 'icp-upload-download',
          dropdownGroup: 'icp',
          isBrokerPortal: true
        },
        {
          name: this.$i18n.t('navigation.payments'),
          path: '/payments',
          scope: 'payments'
        },
        {
          name: this.$i18n.t('navigation.addPaymentDetails'),
          path: '/payment-token/add',
          scope: 'payment-token-add',
          dropdownGroup: 'paymentToken',
          isBrokerPortal: true
        },
        {
          name: this.$i18n.t('navigation.getPaymentDetails'),
          path: '/payment-token/get',
          scope: 'payment-token-get',
          dropdownGroup: 'paymentToken'
        },
        {
          name: this.$i18n.t('navigation.searchPolicy'),
          path: '/search-policy',
          scope: 'search-policy',
          isBrokerPortal: true
        },
        {
          name: this.$i18n.t('navigation.claims'),
          path: '/claims',
          scope: 'claims'
        },
        {
          name: this.$i18n.t('navigation.documents'),
          path: '/documents',
          scope: 'documents'
        },
        {
          name: this.$i18n.t('navigation.users'),
          path: '/admin/users',
          scope: 'users'
        },
        {
          name: this.$i18n.t('navigation.announcements'),
          path: '/admin/announcements',
          scope: 'announcements'
        },
        {
          name: this.$i18n.t('navigation.ratesbot'),
          path: '/admin/ratesbot',
          scope: 'ratesbot'
        },
        {
          name: this.$i18n.t('navigation.faqs'),
          path: '/admin/faqs',
          scope: 'faqs'
        },
        {
          name: this.$i18n.t('navigation.library'),
          path: '/admin/library',
          scope: 'library'
        }
      ]
      if (this.allowRatesbotOffers) {
        data.push(
          {
            name: this.$i18n.t('navigation.specialOffer'),
            path: '',
            scope: 'offer-offer-num'
          }
        )
      }

      if (this.$config.APP_CONFIG.allowChangeRequests && this.$auth.isCustomer) {
        data.push({
          name: this.$i18n.t('navigation.changeRequests'),
          path: '/change-requests/select-policy-type',
          scope: 'change-requests'
        })
      }

      data.push(...[
        {
          name: this.$i18n.t('navigation.faq'),
          path: '/help',
          scope: 'faqs',
          openInNewTab: true,
          isMobileOnly: true
        },
        {
          name: this.$i18n.t('navigation.contact'),
          path: '/contact-us',
          scope: 'contact-us',
          openInNewTab: true,
          isMobileOnly: true
        }
      ])

      return data
    },
    availableLinks () {
      return this.links.reduce((acc, cur) => {
        if (this.$auth.primaryGroup && this.$auth.pagePermissions[this.$auth.primaryGroup].find(p => cur.scope === p) &&
        (!cur.isBrokerPortal || this.$auth.isBrokerPortal)) {
          acc.push(cur)
        }
        return acc
      }, [])
    },
    dropdownGroups () {
      return this.availableLinks.reduce((acc, cur) => {
        if (cur.dropdownGroup) {
          if (Array.isArray(acc[cur.dropdownGroup])) {
            acc[cur.dropdownGroup].push(cur)
          } else {
            acc[cur.dropdownGroup] = [cur]
          }
        }
        return acc
      }, {})
    }
  }
}
