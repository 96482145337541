//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Number,
      default: undefined
    },
    // Key used to match accordion events with correct accordion item when using
    // multiple accordions on one page
    accordionKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      modelValue: this.value
    }
  },
  watch: {
    modelValue: {
      handler (newValue) {
        this.$root.$emit('accordion-value-change', { accordionKey: this.accordionKey, value: newValue })
      }
    }
  },
  mounted () {
    this.$root.$emit('accordion-value-change', { accordionKey: this.accordionKey, value: this.value })
  }
}
