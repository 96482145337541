//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mdiPlus, mdiMinus } from '@mdi/js'

export default {
  props: {
    heading: {
      type: [String, Number],
      default: ''
    },
    subHeading: {
      type: String,
      default: ''
    },
    imgSrc: {
      type: String,
      default: ''
    },
    // Key used to match accordion events with correct accordion item when using
    // multiple accordions on one page
    accordionKey: {
      type: String,
      required: true
    },
    idx: {
      type: Number,
      required: true
    },
    ariaLabel: {
      type: String,
      default: ''
    },
    attrs: {
      type: Object,
      default () {
        return {
          classes: {
            itemExpandedActive: 'item-expanded',
            panelHeader: 'py-6',
            panelContent: 'py-6',
            panelIcon: {}
          },
          imgSpecs: {
            width: 70,
            class: 'mr-11'
          },
          heading: {
            tag: 'h4',
            classes: {}
          },
          subHeading: {
            tag: 'h3',
            classes: {}
          }
        }
      }
    }
  },
  data () {
    return {
      icon: mdiPlus,
      ariaLabelPrefix: this.$i18n.t('expand'),
      modelValue: this.value
    }
  },
  computed: {
    getAriaLabel () {
      return `${this.ariaLabelPrefix} ${this.ariaLabel}`
    }
  },
  mounted () {
    this.$root.$on('accordion-value-change', ({ accordionKey, value }) => {
      // When multiple accordions used on one page, make sure we're catching the correct event using accordionKey
      if (accordionKey === this.accordionKey) {
        if (value === this.idx) {
          this.icon = mdiMinus
          this.ariaLabelPrefix = this.$i18n.t('collapse')
        } else {
          this.icon = mdiPlus
          this.ariaLabelPrefix = this.$i18n.t('expand')
        }
      }
    })
  }
}
