import { render, staticRenderFns } from "./default.vue?vue&type=template&id=370c8273&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=370c8273&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "370c8273",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnnouncementsBar: require('/builds/ratesdotca/scoop/clientportal/components/AnnouncementsBar.vue').default,DashboardMenu: require('/builds/ratesdotca/scoop/clientportal/components/DashboardMenu.vue').default,UserMenu: require('/builds/ratesdotca/scoop/clientportal/components/UserMenu.vue').default,DashboardMenuDrawer: require('/builds/ratesdotca/scoop/clientportal/components/DashboardMenuDrawer.vue').default,ScoopFooter: require('/builds/ratesdotca/scoop/clientportal/components/ScoopFooter.vue').default,NotificationSnackbar: require('/builds/ratesdotca/scoop/clientportal/components/NotificationSnackbar.vue').default,BaseDialog: require('/builds/ratesdotca/scoop/clientportal/components/BaseDialog.vue').default,StoreDebug: require('/builds/ratesdotca/scoop/clientportal/components/StoreDebug.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VApp,VAppBar,VBtn,VMain,VSnackbar})
