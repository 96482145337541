export const state = () => ({
  isUpdate: false
})

export const mutations = {
  SET_IS_UPDATE (s, flag) {
    s.isUpdate = flag
  }
}

export const actions = {
  setIsUpdate ({ commit }, flag) {
    commit('SET_IS_UPDATE', flag)
  }
}
