/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onPostEvent = /* GraphQL */ `
  subscription OnPostEvent($scopeId: String) {
    onPostEvent(scopeId: $scopeId) {
      scopeId
      eventType
    }
  }
`;
