import Vue from 'vue'
import uaParser from 'ua-parser-js'

const dtOptions = { dateStyle: 'full', timeStyle: 'short' }
const tzOptions = { timeZone: 'America/Toronto', timeZoneName: 'short' }
const localeStringOptions = { hour: 'numeric', minute: 'numeric', hour12: true }

class FormatService {
  dateTime (d, locale, options) {
    if (!options) { options = dtOptions }
    return new Intl.DateTimeFormat(`${locale}-CA`, options).format(d)
  }

  localeString (d, locale, options) {
    if (!options) { options = localeStringOptions }
    return d.toLocaleString(`${locale}-CA`, options)
  }

  timeZone (d, locale, options) {
    const timezone = new Intl.DateTimeFormat(`${locale}-CA`, { ...tzOptions, ...options }).format(d)
    return timezone.split(',')[1].trim()
  }

  userAgent (uaString) {
    return uaParser(uaString)
  }

  isObject (o) {
    return o === Object(o) && !this.isArray(o) && typeof o !== 'function'
  }

  isArray (a) {
    return Array.isArray(a)
  }

  toCamel (s) {
    // Return if there is no _ in key
    if (!s.match(/_/g)) {
      return s
    }
    return s.toLowerCase().replace(/([_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('_', '')
    }).replace('_', '')
  }

  keysToCamel (o) {
    if (this.isObject(o)) {
      const n = {}
      Object.keys(o)
        .forEach((k) => {
          n[this.toCamel(k)] = this.keysToCamel(o[k])
        })
      return n
    } else if (this.isArray(o)) {
      return o.map((i) => {
        return this.keysToCamel(i)
      })
    }
    return o
  }

  getDay (idx, short = false) {
    if (idx >= 0 && idx < 7) {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      const daysShort = ['Sun', 'Mon', 'Tues', 'Thurs', 'Fri', 'Sat']
      return short ? daysShort[idx] : days[idx]
    } else {
      return 'Not available'
    }
  }

  getMonth (idx, short = false) {
    if (idx >= 0 && idx < 12) {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      const monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return short ? monthShort[idx] : months[idx]
    } else {
      return 'Not available'
    }
  }

  phone (n) {
    // strip off +1 from the front
    if (n && n.match(/^\+1/)) {
      return n.slice(2)
    }
    return n
  }

  validateVin (vin) {
    try {
      /* eslint-disable object-property-newline */
      const letterMap = {
        A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8,
        J: 1, K: 2, L: 3, M: 4, N: 5, P: 7, R: 9, S: 2,
        T: 3, U: 4, V: 5, W: 6, X: 7, Y: 8, Z: 9
      }
      /* eslint-enable object-property-newline */
      const charMultiplier = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2]
      if (vin.length !== 17) {
        return false
      }
      let sum = 0
      for (let i = 0; i < vin.length; i++) {
        let charVal = vin.toUpperCase().charAt(i)
        if (isNaN(charVal)) {
          if (!letterMap[charVal]) {
            return false
          }
          charVal = letterMap[charVal]
        }
        sum += charVal * charMultiplier[i]
      }
      const prodParts = (sum / 11).toString().split('.')
      const decimal = (prodParts.length !== 2) ? '00' : prodParts[1].substring(0, 2)
      let ninthDigitCheckVal = ''
      if (decimal === '90') {
        ninthDigitCheckVal = 'X'
      } else {
        ninthDigitCheckVal = decimal / 9
      }
      // eslint-disable-next-line eqeqeq
      return vin.charAt(8) == ninthDigitCheckVal
    } catch (err) {
      console.log(err)
    }
    return false
  }

  validateYearFromVin (vin, year) {
    try {
      const yearIndex = (year - 1980) % 30
      const chars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'R', 'S', 'T', 'V', 'W', 'X', 'Y', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      return chars[yearIndex] === vin.charAt(9)
    } catch (err) {
      console.log(err)
    }
    return false
  }

  uppercaseFirst (string) {
    return string[0].toUpperCase() + string.substring(1)
  }

  uppercaseFirstAll (string) {
    const words = string.split(' ')
    return words.map((word) => {
      return this.uppercaseFirst(word)
    }).join(' ')
  }
}

export default (context, inject) => {
  const formatService = new FormatService()
  context.$format = Vue.prototype.$format = Vue.$format = formatService
  inject('format', formatService)
}
