//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppsyncPlayground from 'appsync-playground'
import { mdiChevronUp } from '@mdi/js'
export default {
  name: 'StoreDebug',
  components: {
    AppsyncPlayground
  },
  data () {
    return {
      panel: [0],
      dialogPersistent: false,
      dialog: false,
      tab: 'data_inspector',
      fab: false,
      icons: {
        mdiChevronUp
      },
      queries: {},
      mutations: {}
    }
  },
  computed: {
    vinNums () {
      return [
        'JT2AE96C4L3345782',
        '2C3HH461234567890 (invalid vin)',
        '1C3ES46C6YD731498',
        '1H4KA4647JC800025',
        '1G5DC18D0BF103108',
        'KNADE163166145867',
        '2CNDL13F866206838',
        '1C3ES46C6YD731498',
        '1B3HD46T4SF613045',
        '1FMDU34X5RUB65025',
        '1B3AA4632RF104222',
        '1T2AW15C1F0042304',
        'JH4KA4631LC039518',
        '1A3CX56R7LZ018862',
        '1MHVE31N1RU620558',
        '5FNRL18914B081847',
        'JN8DR09Y61W570071'
      ]
    }
  },
  async beforeMount () {
    try {
      // If files are missing or import fails, assign empty objects
      this.queries = await import('@/src/graphql/queries.js')
      this.mutations = await import('@/src/graphql/mutations.js')
    } catch (err) {
      this.queries = {}
      this.mutations = {}
    }
  },
  methods: {
    toTop () {
      document.getElementsByClassName('v-dialog--active')[0].scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
