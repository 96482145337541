//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LayoutsDefault',
  data () {
    return {
      menu: null,
      fixed: false,
      notification: {
        show: false,
        text: ''
      },
      transcriptDestination: this.$config.CHAT.TRANSCRIPT_STORAGE,
      appBarHeight: this.$vuetify.breakpoint.mdAndUp ? 96 : 64,
      updateAppBarHeight: true,
      showReportForm: false,
      params: [],
      sessionInterval: null,
      timerInterval: null,
      timeoutWarning: false,
      timeoutCountdown: 0,
      blockInterval: false
    }
  },
  head () {
    return {
      title: this.$route.path
    }
  },
  computed: {
    announcements () {
      return this.$store.state.announcements.items.filter(e => !e.closed)
    },
    menuDrawerStyle () {
      return {
        'margin-top': `${this.appBarHeight}px`,
        height: `calc(100% - ${this.appBarHeight}px)`
      }
    },
    mainStyle () {
      if (this.$auth.isAuthenticated && this.$route.name) {
        const styles = {
          'padding-top': `${this.appBarHeight}px`
        }
        if (this.$route.name.startsWith('offer-offer-num')) {
          styles['background-color'] = '#f2f3f8'
        }
        return styles
      }
      return {}
    },
    isChatUserLoaded () {
      const isChatUser = this.$auth.groups?.length === 1 && this.$auth.groups[0] === 'User'
      return this.$auth.isAuthenticated && this.$store.state.profile.loaded && isChatUser
    },
    timeoutMinutes () {
      return Math.floor(this.timeoutCountdown / 60).toString().padStart(2, '0')
    },
    timeoutSeconds () {
      return (this.timeoutCountdown % 60).toString().padStart(2, '0')
    }
  },
  watch: {
    '$vuetify.theme.dark': {
      handler () {
        this.$store.dispatch('settings/setDarkTheme', this.$vuetify.theme.dark)
      }
    },
    '$store.state.workbox.isUpdate': {
      handler (val) {
        if (val) {
          this.$store.dispatch('workbox/setIsUpdate', false)
          location.reload()
        }
      }
    },
    announcements: {
      handler (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.updateAppBarHeight = true
        }
      }
    },
    '$auth.isAuthenticated': {
      handler (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.updateAppBarHeight = true
        }
        this.initChatHandler()
      }
    }
  },
  updated () {
    if (this.updateAppBarHeight) {
      let announcementsHeight = 0
      if (this.$refs['announcements-bar']) {
        announcementsHeight = this.$refs['announcements-bar'].$el.clientHeight || 0
      }
      this.appBarHeight = (this.$vuetify.breakpoint.mdAndUp ? 96 : 64) + announcementsHeight
      this.updateAppBarHeight = false
    }
    this.checkSession()
  },
  mounted () {
    if (this.$store.state.settings) {
      this.$vuetify.theme.dark = this.$store.state.settings.dark
    }
    this.initChatHandler()
  },
  methods: {
    reloadPage () {
      location.reload()
    },
    initChat () {
      let client = []
      if (this.isChatUserLoaded) {
        client = [
          this.$store.state.profile.firstName,
          this.$store.state.profile.lastName,
          this.$auth.email,
          this.$auth.phoneNumber,
          this.$store.state.auth.user.attributes['custom:lookup_codes']
        ]
      }

      const pocNum = this.isChatUserLoaded ? '0d16aafe-f3d1-40ed-88b5-568993bb7c10' : 'cf3d27fc-1371-442c-931b-f5f4f6d3901f'

      window.icPatronChat.init({
        serverHost: `${this.$config.CHAT.RC_URL}`,
        bus_no: 4605792,
        poc: pocNum,
        params: client
      })
    },
    initChatHandler () {
      let count = 0

      const iframeWrapper = document.getElementsByClassName('chat-iframe-wrap')
      if (iframeWrapper.length > 0) {
        document.getElementsByClassName('ie-div-position-customer-chat')[0].remove()
      }

      // Make first attempt without any delay
      if (window.icPatronChat && (!this.$auth.isAuthenticated || this.isChatUserLoaded)) {
        this.initChat()
      } else {
        const refreshIntervalId = setInterval(() => {
          if (window.icPatronChat && (!this.$auth.isAuthenticated || this.isChatUserLoaded)) {
            clearInterval(refreshIntervalId)
            this.initChat()
          } else if (count >= 10) {
            clearInterval(refreshIntervalId)
          }
          count++
        }, 1000)
      }
    },
    checkSession () {
      if (this.sessionInterval) {
        clearInterval(this.sessionInterval) // restart the interval if it's set
      }
      this.sessionInterval = setInterval(async () => {
        if (this.$store.state.auth.user && !this.blockInterval) {
          const currentDate = new Date()
          this.timeoutCountdown = this.$store.state.auth.user.signInUserSession.accessToken.payload.exp -
          0 - // number can be provided here for testing (ex: 2990 for <= 600, 3590 for <= 0)
          Math.round(currentDate.getTime() / 1000) // getTime() in milleseconds; /1000 to convert to seconds
          if (this.timeoutCountdown <= 0) { // timeout exceeded; sign out the user
            this.timeoutWarning = false // close the dialog
            await this.$store.dispatch('auth/logout')
            this.$router.push(this.localePath('/login'))
            this.$dd.removeUser()
          } else if (this.timeoutCountdown <= 600) { // 600 seconds = 10 minutes; display warning to user
            this.timeoutWarning = true
          }
        } else {
          clearInterval(this.sessionInterval) // clear any interval here as it shouldn't be running without this.$store.state.auth.user
        }
      }, 1000)
    },
    async closeTimeout () {
      this.blockInterval = true // block the interval while we make some requests
      this.timeoutWarning = false
      await this.$store.dispatch('auth/refreshSession')
      this.blockInterval = false
      this.checkSession() // restart checkSession()
    }
  }
}
