import { Auth } from 'aws-amplify'

import brokerPortalDomains from '@/src/broker-portal-domains'

export const state = () => ({
  isAuthenticated: false,
  user: null,
  requestedRoute: null
})

export const mutations = {
  SET (s, user) {
    s.isAuthenticated = !!user
    s.user = Object.freeze(user)
  },
  SET_REQUESTED_ROUTE (s, route) {
    s.requestedRoute = route
  }
}

export const actions = {

  // When using store commit/dispatch functions, the context is the auth when executed here

  setRequestedRoute ({ commit }, route) {
    commit('SET_REQUESTED_ROUTE', route)
  },

  async load ({ commit }, { bypassCache }) {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache })
      await user.getCachedDeviceKeyAndPassword()
      commit('SET', user)
      return user
    } catch (err) {
      commit('SET', null)
    }
  },

  async register (_, { email, password }) {
    return await Auth.signUp({
      username: email,
      password
    })
  },

  async confirmRegistration (_, { email, code }) {
    return await Auth.confirmSignUp(email, code)
  },

  async login ({ commit }, { email, password }) {
    const user = await Auth.signIn(email, password)
    if (!user?.signInUserSession?.accessToken?.payload['cognito:groups'].some(x => /^(?:Admin|ServiceTeamLead|SalesTeamLead|ServiceBroker|SalesBroker)$/.test(x)) && brokerPortalDomains.includes(location.host)) {
      // not allowed to log in to Broker Portal if User; sign them out and throw an error
      await Auth.signOut()
      throw new Error('BrokerPortalDenied')
    } else if (user?.signInUserSession?.accessToken?.payload['cognito:groups'].some(x => /^(?:Admin|ServiceTeamLead|SalesTeamLead|ServiceBroker|SalesBroker)$/.test(x)) &&
    !brokerPortalDomains.includes(location.host) && !location.host.includes('localhost')) {
      // reverse scenario: not allowed to log in to my.scoop if Worker; sign them out and throw an error
      await Auth.signOut()
      throw new Error('UserPortalDenied')
    }
    // need to handle challenges:
    // NEW_PASSWORD_REQUIRED
    if (!user.challengeName) {
      commit('SET', user)
    }
    return user
  },

  async completeNewPassword ({ dispatch }, { email, password, newPassword }) {
    const signin = await Auth.signIn(email, password)
    await Auth.completeNewPassword(signin, newPassword, { email })
  },

  async confirmSignIn ({ dispatch }, { user, code, mfaType, remember }) {
    const confirmUser = await Auth.confirmSignIn(user, code, mfaType)
    if (remember) {
      confirmUser.setDeviceStatusRemembered({
        onSuccess (result) {
          // console.log('onSuccess, call result: ' + result)
        },
        // eslint-disable-next-line node/handle-callback-err
        onFailure (err) {
          // console.log('onFailure, ' + err)
        }
      })
    }
  },

  async changePassword (_, { oldPassword, newPassword }) {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, oldPassword, newPassword)
  },

  async updateAttributes (_, { attrs }) {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, attrs)
  },

  async setPreferredMFA (_, { on }) {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.setPreferredMFA(user, (on) ? 'SMS' : 'NOMFA')
  },

  async verifyUserAttribute (_, { attr }) {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.verifyUserAttribute(user, attr)
  },

  async verifyUserAttributeConfirm ({ dispatch }, { attr, code }) {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.verifyUserAttributeSubmit(user, attr, code)
  },

  async forgotPassword (_, { email }) {
    await Auth.forgotPassword(email)
  },

  async forgotPasswordSubmit (_, { email, code, password }) {
    await Auth.forgotPasswordSubmit(email, code, password)
  },

  // eslint-disable-next-line require-await
  async logout ({ commit }) {
    await Auth.signOut()
    // Auth.signOut seems to throw console errors;
    // Error: [vuex] do not mutate vuex store state outside mutation handlers.
    commit('SET', null)
  },

  async federatedSignIn () {
    await Auth.federatedSignIn({ provider: 'RatesDotCaProvider' })
  },

  async refreshSession ({ commit, state }) {
    const user = await Auth.currentAuthenticatedUser()
    return new Promise((resolve) => {
      // unfortunately, when returning refreshSession(), while it's a Promise it fulfills before the callback below actually executes
      // instead, return our own Promise where we can indicate that it resolve()'s once the callback is done
      user.refreshSession(user.signInUserSession.refreshToken, (err, signInUserSession) => {
        if (!err) {
          // update user session in store
          const user = Object.assign({}, state.user, { signInUserSession })
          commit('SET', user)
          resolve()
        }
      })
    })
  }
}
