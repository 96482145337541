export const state = () => ({
  dark: process.env.APP_CONFIG.defaultTheme === 'dark',
  tours: {
    index: true,
    lastSeenSlide: -1
  },
  remember: {
    email: ''
  }
})

export const mutations = {
  SET_DARK (s, dark) {
    s.dark = dark
  },
  SET_TOUR (s, { page, show, lastSeenSlide }) {
    const update = {}
    update[page] = show
    update.lastSeenSlide = lastSeenSlide
    s.tours = Object.assign(s.tours, update)
  },
  SET_REMEMBER (s, { email }) {
    const update = {}
    update.email = email
    s.remember = Object.assign(s.remember, update)
  }
}

export const actions = {
  setDarkTheme ({ commit }, dark) {
    commit('SET_DARK', dark)
  },
  setTourDisplay ({ commit }, { page, show, lastSeenSlide }) {
    commit('SET_TOUR', { page, show, lastSeenSlide })
  },
  setRemember ({ commit }, { email }) {
    commit('SET_REMEMBER', { email })
  }
}
