import Vue from 'vue'

export const state = () => ({
  loaded: false,
  items: []
})

export const mutations = {
  SET_ANNOUNCEMENTS (s, announcements) {
    s.items = announcements
    s.loaded = true
  },
  CLOSE_ANNOUNCEMENT (s, announcementId) {
    s.items = s.items.map((item) => {
      if (item.id === announcementId) {
        item.closed = true
      }
      return item
    })
  },
  CLEAR_ANNOUNCEMENTS (s) {
    s.items = []
    s.loaded = false
  }
}

export const actions = {
  async setAnnouncements ({ commit, state: currentState }, forceRequest = false) {
    if (!currentState.loaded || forceRequest) {
      const closedAnnouncementIds = currentState.items.reduce((acc, cur) => {
        if (cur.closed) {
          acc.push(cur.id)
        }
        return acc
      }, [])
      const searchParams = {
        from: 0,
        size: 50, // size should ideally be less, so hardcoding here
        sort: '[]'
      }
      const searchQuery = {
        bool: {
          must: [
            { term: { enabled: true } }
          ]
        }
      }
      searchParams.query = JSON.stringify(searchQuery)
      const res = await Vue.prototype.$api.searchAnnouncements(searchParams)
      res.hits.sort((a, b) => {
        if (a.order > b.order) {
          return 1
        } else if (a.order < b.order) {
          return -1
        }
        return 0
      })
      const announcements = res.hits.map((announcement) => {
        if (closedAnnouncementIds.includes(announcement.id)) {
          announcement.closed = true
        }
        return announcement
      })
      commit('SET_ANNOUNCEMENTS', announcements)
    }
  },
  closeAnnouncement ({ commit }, announcementId) {
    commit('CLOSE_ANNOUNCEMENT', announcementId)
  },
  clearAnnouncements ({ commit }) {
    commit('CLEAR_ANNOUNCEMENTS')
  }
}
