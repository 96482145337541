const defaults = {
  settings: {
    enabled: false,
    rateComparison: {
      dollars: null,
      percentage: null
    }
  }
}

export const state = () => ({
  settings: defaults.settings
})

export const mutations = {
  SET_SETTINGS (s, settings) {
    s.settings = { ...settings }
  }
}

export const actions = {
  setSettings ({ commit, state: currentState }, settings) {
    commit('SET_SETTINGS', settings)
  }
}
